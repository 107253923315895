<script>
import { defineComponent } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import DefaultDrawerEPurchases from '/~/components/drawer/views/e-purchases/drawer-e-purchases.vue'
import BaseTabs from '/~/templates/australiapost/components/base/tabs/base-tabs.vue'
import ArchivedPurchases from '/~/templates/australiapost/components/drawer/views/e-purchases/drawer-e-purchases-archived.vue'
import CurrentPurchases from '/~/templates/australiapost/components/drawer/views/e-purchases/drawer-e-purchases-current.vue'

export default defineComponent({
  components: {
    CurrentPurchases,
    ArchivedPurchases,
    BaseAsidePage,
    BaseTabs,
  },
  extends: DefaultDrawerEPurchases,
})
</script>

<template>
  <base-aside-page title="My ePurchases" no-padding>
    <template #action>
      <base-button
        icon="plain/reload"
        :class="[loading && 'animate-spin']"
        :size="32"
        @click="updatePurchases"
      />
    </template>

    <base-tabs v-if="!isPurchasesEmpty" v-model="tab" class="h-full">
      <base-tab name="Current" value="current" keep-alive>
        <current-purchases :to="to" />
      </base-tab>
      <base-tab name="Archived" value="archived" keep-alive>
        <archived-purchases />
      </base-tab>
    </base-tabs>

    <base-state
      v-else-if="!isPurchasesLoading"
      title="You have no ePurchases"
      image="/empty/empty-epurchases.svg"
      class="min-h-full p-5"
    >
      <p>
        Access and manage your digital eGift Cards and eVouchers, ready for
        instant in-store redemption.
      </p>
    </base-state>
  </base-aside-page>
</template>
