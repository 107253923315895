<script>
import { defineComponent } from 'vue'
import DefaultBaseTabs from '/~/components/base/tabs/base-tabs.vue'

export default defineComponent({
  extends: DefaultBaseTabs,
})
</script>

<template>
  <div
    class="flex flex-col"
    :class="[disabled && 'pointer-events-none grayscale']"
    :disabled="disabled"
  >
    <ul class="flex shrink-0 items-center overflow-hidden">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        class="relative h-full flex-1"
      >
        <a
          :href="tab.href"
          class="flex h-full max-w-full flex-col justify-center border-b-3 px-2.5 py-2.5 text-center font-bold text-gray-300"
          :class="[tab.isActive && 'text-primary']"
          @click.prevent="changeTab(tab)"
        >
          {{ tab.name }}
        </a>
        <div
          class="absolute bottom-0 left-0 w-full transform border-b-3 border-primary transition duration-200"
          :class="[tab.isActive ? 'scale-x-100' : 'scale-x-0']"
        />
      </li>
    </ul>

    <div
      v-if="$slots.default"
      ref="body"
      class="flex grow flex-col overflow-y-auto"
    >
      <slot />
    </div>
  </div>
</template>
